import React from 'react';


//CORE COMPONENTS
import {
  ThemeProvider,
  styled
} from '../../components-core/packages/components-core/dist/theme-provider/index';

//LAYOUT
import {
  Container,
  ContainerWrap,
  ContainerPadding,
  ContainerHorizontalAlignment,
  ContainerGutter,
} from '../../components-core/packages/components-core/dist/container/index';
import Layout from '../components/layout';

//STYLE
import { Text, TextAppearance, } from '../../components-core/packages/components-core/dist/text';
import { Link } from '../../components-core/packages/components-core/dist/link';
import { navigate } from "@reach/router"

//ICONS CORE
import { ArrowRight } from '../../components-core/packages/icons-core/dist/ArrowRight';


const NotFoundPage = () => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  if (url.includes('404/?404') || url.includes('aspx')) {
    if(typeof window !== 'undefined'){
      window.location = '/ana-sayfa';
    }
  } else {
    return (
      <Layout theme={'main'} >
        <ThemeProvider>
          <Container
            padding={{
              left: ContainerPadding.grid002,
              right: ContainerPadding.grid002,
              top: ContainerPadding.dynamic0450,
            }}
            wrap={ContainerWrap.always}
            gutter={ContainerGutter.dynamic0200}
          >
            <Container wrap={ContainerWrap.always} gutter={ContainerGutter.dynamic0050} >
              <Text appearance={TextAppearance.headline0350} bold>
                Maalesef, bu sayfa mevcut değil.
              </Text>
              <Text appearance={TextAppearance.copy0200}>
                Aradığınızı aşağıdaki sayfalardan birinde bulabilirsiniz:
              </Text>
            </Container>

            <Container gutter={ContainerGutter.dynamic0050} wrap={ContainerWrap.always}>
              {renderLinks()}
            </Container>
          </Container>
        </ThemeProvider>
      </Layout>
    )
  }

}

const renderLinks = () => {
  const links = [
    {
      'name': 'Ana Sayfa',
      'link': 'ana-sayfa'
    },
    {
      'name': 'Fiyat listesi',
      'link': 'fiyat-listesi'
    },
  ]

  return links.map(data =>
    <Text appearance={TextAppearance.copy0200} bold>
      <Link href={data.link} target={'_parent'}>
        {data.name}<ArrowRight variant={'small'} />
      </Link>
    </Text>
  )
}

export default NotFoundPage;
